var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.StateAuthenticated)?_c('v-app',[(_vm.StateAuthenticated)?_c('v-app-bar',{staticClass:"black",attrs:{"app":"","clipped-left":"","elevation":"1","elevate-on-scroll":"","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}}),_c('v-img',{attrs:{"src":require('@/assets/firstvaultblackbgg.png'),"contain":"","max-width":"55","width":"100%"}}),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.StateAuthenticated)?_c('v-btn',_vm._g(_vm._b({staticClass:"my-tabs white--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fullName)+" "),_c('v-avatar',{attrs:{"size":"36px"}},[(_vm.userImage)?_c('img',{attrs:{"src":_vm.userImage}}):_c('v-icon',[_vm._v("mdi-account-question")])],1)],1):_vm._e()]}}],null,false,3699493210)},[_c('v-list',[_vm._l((_vm.profileItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.path,"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Logout")])],1)],2)],1)],1)],1):_vm._e(),(_vm.StateAuthenticated)?_c('v-navigation-drawer',{class:_vm.navigationDrawerClass,attrs:{"color":"#424242","dark":"","value":_vm.drawer,"app":"","fixed":"","flat":"","bottom":"","hide-overlay":"","mini-variant":_vm.mini,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"temporary":_vm.$vuetify.breakpoint.smAndDown},on:{"update:value":function($event){_vm.drawer=$event},"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}}},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.name,attrs:{"to":item.path,"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1):_vm._e(),_c('v-main',{staticClass:"topBar"},[(_vm.StateAuthenticated)?_c('keep-alive',{attrs:{"exclude":[
        'Login',
        'VerifyMFACode',
        'EnableMFA',
        'UpdateProfile',
        'VaultServiceAccounts' ]}},[_c('router-view')],1):_c('router-view')],1)],1):_c('v-app',[_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app v-if="StateAuthenticated">
    <v-app-bar
      app
      clipped-left
      elevation="1"
      elevate-on-scroll
      dark
      v-if="StateAuthenticated"
      class="black"
    >
      <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>
      <v-img
        :src="require('@/assets/firstvaultblackbgg.png')"
        contain
        max-width="55"
        width="100%"
      />
      <!-- <v-img
        v-else
        dark
        src="@/assets/firstvaultblackbgg.png"
        max-height="60"
        max-width="60"
        class="ma-2"
      ></v-img> -->
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="StateAuthenticated"
              class="my-tabs white--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ fullName }}
              <v-avatar size="36px">
                <img v-if="userImage" :src="userImage" />
                <v-icon v-else>mdi-account-question</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in profileItems"
              :key="index"
              :to="item.path"
              link
              ><v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      :class="navigationDrawerClass"
      v-if="StateAuthenticated"
      color="#424242"
      dark
      :value.sync="drawer"
      app
      fixed
      flat
      bottom
      hide-overlay
      :mini-variant.sync="mini"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <v-list nav dense>
        <v-list-item
          v-for="item in items"
          :key="item.name"
          :to="item.path"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="topBar">
      <!-- this will cache the components state if user is authenticated-->
      <keep-alive
        :exclude="[
          'Login',
          'VerifyMFACode',
          'EnableMFA',
          'UpdateProfile',
          'VaultServiceAccounts',
        ]"
        v-if="StateAuthenticated"
      >
        <router-view />
      </keep-alive>
      <router-view v-else></router-view>
    </v-main>
  </v-app>
  <v-app v-else>
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BaseView",
  metaInfo: {
    title: "App",
    titleTemplate: "%s | FirstVault",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {},
  computed: {
    ...mapGetters(["StateUser", "StateAuthenticated", "StateEnterprise"]),
    navigationDrawerClass() {
      if (this.$vuetify.breakpoint.smAndDown) return "smallDrawer";
      else return "largeDrawer";
    },
    fullName() {
      return this.StateUser.first_name + " " + this.StateUser.last_name;
    },
    userImage() {
      return this.StateUser.picture;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    drawer() {
      if (this.$vuetify.breakpoint.smAndDown) return !this.mini;
      else return this.mini;
    },
    items() {
      var menuList = [];
      this.$router.options.routes[0].children.forEach((value) => {
        if (Object.prototype.hasOwnProperty.call(value, "meta")) {
          if (
            value.meta.requiresAuth ||
            (this.StateEnterprise && value.meta.requiresEnterpriseAuth)
          ) {
            if (
              !(
                value.name === "Enable MFA" ||
                value.name === "Update Profile" ||
                value.name === "Vault Files"
              )
            )
              menuList.push(value);
            else if (value.name !== "Vault Files")
              this.profileItems.push(value);
          }
        }
      });
      return menuList;
    },
  },
  beforeDestroy() {
    localStorage.clear();
  },
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      alert("You have been successfully Logged out");
      this.$router.push("/");
      window.open(process.env.VUE_APP_UI_URL, "_self");
    },
  },
  data: () => ({
    mini: true,
    profileItems: [],
  }),
};
</script>

<style lang="sass">
.topBar
  padding-top: 70px !important

.largeDrawer
  top: 62px !important
  max-height: calc(100% - 126px) !important
  width: 56px

.smallDrawer
  width: 56px
  z-index: 10

#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none

  @media screen and (max-width: 959px)
    overflow-y: hidden
    padding-top: 48px !important
    padding-bottom: 48px !important
</style>